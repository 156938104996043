<div class="nav-left-wrap" #nav>
  <div class="nav-left">
    <div class="logo" #logoImg>
      <span><img src="./../assets/images/logo.svg" alt="Moniic" /></span>
      <span>{{ roleName | titleCase }}</span>
    </div>
    <div class="menu-wrap" #menuWap>
      <ul>
        <li>
          <a class="profile" [routerLink]="routeProfile" routerLinkActive="active">
            <div class="profile_image">
              <img src="{{ rowData.profileImage || userDefault }}" alt="user" />
            </div>
            <div *ngIf="!smallScreen" class="profile_content">
              <h4>{{ rowData.fullName || "---" }}</h4>
              <p>{{ roleName | titleCase }}</p>
            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="routeAnalytics" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen"> Dashboard </span>
          </a>
        </li>
        <li class="disabled">
          <a *ngIf="isSuperAdmin || isLimoAdmin" [routerLink]="routeAdministration" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <path
                d="M-1709.617,1649.5a1.965,1.965,0,0,0-1.963,1.963,1.966,1.966,0,0,0,1.963,1.964,1.966,1.966,0,0,0,1.963-1.964A1.965,1.965,0,0,0-1709.617,1649.5Z"
                transform="translate(1716.117 -1644.963)" />
              <path class="a"
                d="M-1802.8,1551.78a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5A6.5,6.5,0,0,0-1802.8,1551.78Zm3.517,10.624a5.977,5.977,0,0,0-3.517-1.139,5.977,5.977,0,0,0-3.516,1.139,5.371,5.371,0,0,1-.716-.74,7.02,7.02,0,0,1,2.268-1.141,2.977,2.977,0,0,1-1.019-2.243,2.988,2.988,0,0,1,2.984-2.984,2.988,2.988,0,0,1,2.985,2.984,2.977,2.977,0,0,1-1.019,2.243,7.018,7.018,0,0,1,2.268,1.141A5.391,5.391,0,0,1-1799.28,1562.4Z"
                transform="translate(1809.297 -1551.78)" />
            </svg>
            <span *ngIf="!smallScreen"> Administrators </span>
          </a>
        </li>

        <li *ngIf="isLimoAdmin && roleName != 'PARTNER'">
          <a routerLink="/customers" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.999" height="12.998" viewBox="0 0 12.999 12.998">
              <path
                d="M-2446.555,1688.87a.706.706,0,0,0-.705.7.706.706,0,0,0,.705.7.705.705,0,0,0,.7-.7A.705.705,0,0,0-2446.555,1688.87Z"
                transform="translate(2454.983 -1683.076)" />
              <path class="a"
                d="M-2488.1,1620.91a.706.706,0,0,0,.7-.7.706.706,0,0,0-.7-.7.706.706,0,0,0-.705.7A.706.706,0,0,0-2488.1,1620.91Z"
                transform="translate(2494.604 -1616.926)" />
              <path class="a"
                d="M-2529.655,1688.87a.706.706,0,0,0-.705.7.706.706,0,0,0,.705.7.706.706,0,0,0,.7-.7A.706.706,0,0,0-2529.655,1688.87Z"
                transform="translate(2534.225 -1683.076)" />
              <path class="a"
                d="M-2607.125,1564.05a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5A6.5,6.5,0,0,0-2607.125,1564.05Zm0,1.646a1.635,1.635,0,0,1,1.634,1.633,1.636,1.636,0,0,1-1.634,1.634,1.635,1.635,0,0,1-1.633-1.634A1.635,1.635,0,0,1-2607.125,1565.7Zm3.845,8.125a5.323,5.323,0,0,0-3.845-1.639,5.32,5.32,0,0,0-3.843,1.639,4.959,4.959,0,0,1-.542-.771,6.287,6.287,0,0,1,1.573-1.128,1.633,1.633,0,0,1-.75-1.373,1.636,1.636,0,0,1,1.634-1.633,1.635,1.635,0,0,1,1.633,1.633,1.624,1.624,0,0,1-.169.722c.153-.011.308-.017.464-.017s.311.006.465.017a1.617,1.617,0,0,1-.169-.722,1.635,1.635,0,0,1,1.633-1.633,1.635,1.635,0,0,1,1.633,1.633,1.633,1.633,0,0,1-.75,1.373,6.288,6.288,0,0,1,1.573,1.129A5.009,5.009,0,0,1-2603.28,1573.821Z"
                transform="translate(2613.624 -1564.05)" />
            </svg>
            <span *ngIf="!smallScreen">
              {{ "menuLink.customers" | translate }}
            </span>
          </a>
        </li>
        <li *ngIf="isLimoAdmin && roleName != 'PARTNER'">
          <a routerLink="/subscriptions" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.999" height="12.998" viewBox="0 0 12.999 12.998">
              <path
                d="M-2446.555,1688.87a.706.706,0,0,0-.705.7.706.706,0,0,0,.705.7.705.705,0,0,0,.7-.7A.705.705,0,0,0-2446.555,1688.87Z"
                transform="translate(2454.983 -1683.076)" />
              <path class="a"
                d="M-2488.1,1620.91a.706.706,0,0,0,.7-.7.706.706,0,0,0-.7-.7.706.706,0,0,0-.705.7A.706.706,0,0,0-2488.1,1620.91Z"
                transform="translate(2494.604 -1616.926)" />
              <path class="a"
                d="M-2529.655,1688.87a.706.706,0,0,0-.705.7.706.706,0,0,0,.705.7.706.706,0,0,0,.7-.7A.706.706,0,0,0-2529.655,1688.87Z"
                transform="translate(2534.225 -1683.076)" />
              <path class="a"
                d="M-2607.125,1564.05a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5A6.5,6.5,0,0,0-2607.125,1564.05Zm0,1.646a1.635,1.635,0,0,1,1.634,1.633,1.636,1.636,0,0,1-1.634,1.634,1.635,1.635,0,0,1-1.633-1.634A1.635,1.635,0,0,1-2607.125,1565.7Zm3.845,8.125a5.323,5.323,0,0,0-3.845-1.639,5.32,5.32,0,0,0-3.843,1.639,4.959,4.959,0,0,1-.542-.771,6.287,6.287,0,0,1,1.573-1.128,1.633,1.633,0,0,1-.75-1.373,1.636,1.636,0,0,1,1.634-1.633,1.635,1.635,0,0,1,1.633,1.633,1.624,1.624,0,0,1-.169.722c.153-.011.308-.017.464-.017s.311.006.465.017a1.617,1.617,0,0,1-.169-.722,1.635,1.635,0,0,1,1.633-1.633,1.635,1.635,0,0,1,1.633,1.633,1.633,1.633,0,0,1-.75,1.373,6.288,6.288,0,0,1,1.573,1.129A5.009,5.009,0,0,1-2603.28,1573.821Z"
                transform="translate(2613.624 -1564.05)" />
            </svg>
            <span *ngIf="!smallScreen">
              {{ "menuLink.subscriptions" | translate }}
            </span>
          </a>
        </li>
				
        <li *ngIf="roleName != 'PARTNER'">
          <a (click)="toggleSubMenu('Partners')">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <path
                d="M-1709.617,1649.5a1.965,1.965,0,0,0-1.963,1.963,1.966,1.966,0,0,0,1.963,1.964,1.966,1.966,0,0,0,1.963-1.964A1.965,1.965,0,0,0-1709.617,1649.5Z"
                transform="translate(1716.117 -1644.963)" />
              <path class="a"
                d="M-1802.8,1551.78a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5A6.5,6.5,0,0,0-1802.8,1551.78Zm3.517,10.624a5.977,5.977,0,0,0-3.517-1.139,5.977,5.977,0,0,0-3.516,1.139,5.371,5.371,0,0,1-.716-.74,7.02,7.02,0,0,1,2.268-1.141,2.977,2.977,0,0,1-1.019-2.243,2.988,2.988,0,0,1,2.984-2.984,2.988,2.988,0,0,1,2.985,2.984,2.977,2.977,0,0,1-1.019,2.243,7.018,7.018,0,0,1,2.268,1.141A5.391,5.391,0,0,1-1799.28,1562.4Z"
                transform="translate(1809.297 -1551.78)" />
            </svg>
            <span *ngIf="!smallScreen"> Partners </span>
            <span class="arrow" [class.active]="showSubMenu == 'Partners'">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <defs>
                  <style>
                    .a {
                      fill: #f0f2f5;
                    }

                    .b {
                      fill: #03318c;
                    }
                  </style>
                </defs>
                <rect class="a" width="20" height="20" rx="4" />
                <path class="b"
                  d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                  transform="translate(0.736 -2.785)" />
              </svg>
            </span>
          </a>
          <ul class="submenu submenu1" *ngIf="showSubMenu === 'Partners'">
            <li>
              <a [routerLink]="routePartnersCars" routerLinkActive="active">Car</a>
            </li>
            <li>
              <a [routerLink]="routePartnersYachts" routerLinkActive="active">Yachts</a>
            </li>
          </ul>
        </li>
        <li class="disabled">
          <a routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="9.821" viewBox="0 0 13.094 9.821">
              <path
                d="M12.787,7.364H11.256L10.83,6.3A2.85,2.85,0,0,0,8.171,4.5H4.923A2.851,2.851,0,0,0,2.264,6.3L1.838,7.364H.307a.307.307,0,0,0-.3.381l.153.614a.307.307,0,0,0,.3.232H.974A1.625,1.625,0,0,0,.409,9.82v1.228a1.623,1.623,0,0,0,.409,1.072V13.5a.818.818,0,0,0,.818.818h.818a.818.818,0,0,0,.818-.818v-.818H9.821V13.5a.818.818,0,0,0,.818.818h.818a.818.818,0,0,0,.818-.818V12.119a1.622,1.622,0,0,0,.409-1.072V9.82a1.626,1.626,0,0,0-.564-1.228h.513a.307.307,0,0,0,.3-.232l.153-.614a.307.307,0,0,0-.3-.381Zm-9-.456a1.228,1.228,0,0,1,1.14-.772H8.171a1.228,1.228,0,0,1,1.14.772l.51,1.274H3.274l.51-1.274ZM2.455,11.042a.816.816,0,1,1,0-1.632,1.574,1.574,0,0,1,1.228,1.224C3.683,11.124,2.946,11.042,2.455,11.042Zm8.184,0c-.491,0-1.228.082-1.228-.408A1.574,1.574,0,0,1,10.639,9.41a.816.816,0,1,1,0,1.632Z"
                transform="translate(0 -4.5)" />
            </svg>
            <span *ngIf="!smallScreen"> Agents </span>
          </a>
        </li>
        <li>
          <a (click)="toggleSubMenu('Assets')">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="9.821" viewBox="0 0 13.094 9.821">
              <path
                d="M12.787,7.364H11.256L10.83,6.3A2.85,2.85,0,0,0,8.171,4.5H4.923A2.851,2.851,0,0,0,2.264,6.3L1.838,7.364H.307a.307.307,0,0,0-.3.381l.153.614a.307.307,0,0,0,.3.232H.974A1.625,1.625,0,0,0,.409,9.82v1.228a1.623,1.623,0,0,0,.409,1.072V13.5a.818.818,0,0,0,.818.818h.818a.818.818,0,0,0,.818-.818v-.818H9.821V13.5a.818.818,0,0,0,.818.818h.818a.818.818,0,0,0,.818-.818V12.119a1.622,1.622,0,0,0,.409-1.072V9.82a1.626,1.626,0,0,0-.564-1.228h.513a.307.307,0,0,0,.3-.232l.153-.614a.307.307,0,0,0-.3-.381Zm-9-.456a1.228,1.228,0,0,1,1.14-.772H8.171a1.228,1.228,0,0,1,1.14.772l.51,1.274H3.274l.51-1.274ZM2.455,11.042a.816.816,0,1,1,0-1.632,1.574,1.574,0,0,1,1.228,1.224C3.683,11.124,2.946,11.042,2.455,11.042Zm8.184,0c-.491,0-1.228.082-1.228-.408A1.574,1.574,0,0,1,10.639,9.41a.816.816,0,1,1,0,1.632Z"
                transform="translate(0 -4.5)" />
            </svg>
            <span *ngIf="!smallScreen"> Assets </span>
            <span class="arrow" [class.active]="showSubMenu == 'Assets'">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <defs>
                  <style>
                    .a {
                      fill: #f0f2f5;
                    }

                    .b {
                      fill: #03318c;
                    }
                  </style>
                </defs>
                <rect class="a" width="20" height="20" rx="4" />
                <path class="b"
                  d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                  transform="translate(0.736 -2.785)" />
              </svg>
            </span>
          </a>
          <ul class="submenu submenu1" *ngIf="showSubMenu === 'Assets'">
            <li>
              <a [routerLink]="routeCars" routerLinkActive="active">Car</a>
            </li>
            <li>
              <a *ngIf="roleName != 'PARTNER'" [routerLink]="routeYachts" routerLinkActive="active">Yachts</a>
            </li>
						<li>
							<a routerLink="/driver" routerLinkActive="active">
								<svg xmlns="http://www.w3.org/2000/svg" width="13.153" height="9.207" viewBox="0 0 13.153 9.207">
									<path
										d="M1.973,6.2A1.315,1.315,0,1,0,.658,4.881,1.317,1.317,0,0,0,1.973,6.2Zm9.207,0A1.315,1.315,0,1,0,9.865,4.881,1.317,1.317,0,0,0,11.18,6.2Zm.658.658H10.523a1.311,1.311,0,0,0-.927.382,3.006,3.006,0,0,1,1.543,2.248H12.5a.657.657,0,0,0,.658-.658V8.169A1.317,1.317,0,0,0,11.838,6.854Zm-5.261,0a2.3,2.3,0,1,0-2.3-2.3A2.3,2.3,0,0,0,6.577,6.854Zm1.578.658H7.984a3.178,3.178,0,0,1-2.816,0H5A2.368,2.368,0,0,0,2.631,9.879v.592a.987.987,0,0,0,.986.986H9.536a.987.987,0,0,0,.986-.986V9.879A2.368,2.368,0,0,0,8.155,7.511Zm-4.6-.275a1.311,1.311,0,0,0-.927-.382H1.315A1.317,1.317,0,0,0,0,8.169v.658a.657.657,0,0,0,.658.658H2.012A3.014,3.014,0,0,1,3.558,7.236Z"
										transform="translate(0 -2.25)" />
								</svg>
								<span *ngIf="!smallScreen">
									{{ "menuLink.driversManagement" | translate }}
								</span>
							</a>
						</li>
          </ul>
        </li>
        <li  *ngIf="roleName != 'PARTNER'">
          <a (click)="toggleSubMenu('Activities')">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <defs>
                <style>
                  .a,
                  .b {
                    fill: #fff;
                  }

                  .b {
                    stroke: #03318c;
                  }

                  .b,
                  .c {
                    stroke-miterlimit: 10;
                  }

                  .c {
                    fill: none;
                    stroke: #fff;
                  }
                </style>
              </defs>
              <rect class="a" width="13" height="11.009" rx="1" transform="translate(0 1.991)" />
              <path d="M-2456.671,1240.03l-3.942,3.942-1.971-1.971" transform="translate(2466.127 -1234.832)" />
              <g transform="translate(2.298)">
                <line class="c" y2="1.991" transform="translate(0)" />
                <line class="c" y2="1.991" transform="translate(8.404)" />
              </g>
            </svg>
            <span *ngIf="!smallScreen"> Activities </span>
            <span class="arrow" [class.active]="showSubMenu == 'Activities'">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <defs>
                  <style>
                    .a {
                      fill: #f0f2f5;
                    }

                    .b {
                      fill: #03318c;
                    }
                  </style>
                </defs>
                <rect class="a" width="20" height="20" rx="4" />
                <path class="b"
                  d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                  transform="translate(0.736 -2.785)" />
              </svg>
            </span>
          </a>
          <ul class="submenu submenu1" *ngIf="showSubMenu === 'Activities'">
            <li>
              <a [routerLink]="carActivities" routerLinkActive="active">Car Bookings</a>
            </li>
            <li>
              <a [routerLink]="yachtActivities" routerLinkActive="active">Yachts Bookings</a>
            </li>
            <li>
              <a [routerLink]="personalAssistantsTasks" routerLinkActive="active">Service Requests</a>
            </li>
          </ul>
        </li>
        <li  *ngIf="roleName === 'PARTNER'">
          <a [routerLink]="carActivities" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <defs>
                <style>
                  .a {
                    fill: #f0f2f5;
                  }

                  .b {
                    fill: #03318c;
                  }
                </style>
              </defs>
              <rect class="a" width="20" height="20" rx="4" />
              <path class="b"
                d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                transform="translate(0.736 -2.785)" />
            </svg>
            <span *ngIf="!smallScreen">Bookings</span>
          </a>
        </li>
        <li *ngIf="isLimoAdmin" class="disabled">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.999" height="13" viewBox="0 0 12.999 13">
              <defs>
                <style>
                  .a {
                    fill: #ffffff;
                  }

                  .b {
                    fill: #03318c;
                  }
                </style>
              </defs>
              <path class="a"
                d="M4.508,13a1.689,1.689,0,0,1-1.582-1.1l-.4-1.073a.607.607,0,0,0-.355-.354l-1.072-.4A1.69,1.69,0,0,1,.152,7.8L.626,6.753a.6.6,0,0,0,0-.5L.152,5.208A1.69,1.69,0,0,1,1.1,2.928l1.073-.4a.607.607,0,0,0,.354-.355l.4-1.072A1.69,1.69,0,0,1,5.207.153L6.25.627a.6.6,0,0,0,.5,0h0L7.795.153a1.69,1.69,0,0,1,2.281.945l.4,1.073a.607.607,0,0,0,.355.354l1.072.4a1.69,1.69,0,0,1,.945,2.281l-.474,1.043a.6.6,0,0,0,0,.5L12.85,7.8a1.69,1.69,0,0,1-.945,2.281l-1.073.4a.607.607,0,0,0-.354.355l-.4,1.072a1.69,1.69,0,0,1-2.281.945l-1.043-.474a.6.6,0,0,0-.5,0l-1.043.475a1.7,1.7,0,0,1-.7.151Z"
                transform="translate(-0.001 -0.002)" />
              <path class="b" d="M8.1,9.2A1.1,1.1,0,1,1,9.2,8.1,1.1,1.1,0,0,1,8.1,9.2Z"
                transform="translate(-3.22 -3.221)" />
              <path class="b" d="M14.1,15.2a1.1,1.1,0,1,1,1.1-1.1A1.1,1.1,0,0,1,14.1,15.2Z"
                transform="translate(-5.98 -5.981)" />
              <path class="b"
                d="M8.55,12.5a.55.55,0,0,1-.44-.88l3.3-4.4a.55.55,0,0,1,.88.66l-3.3,4.4a.549.549,0,0,1-.441.22Z"
                transform="translate(-3.701 -3.251)" />
            </svg>
            <span *ngIf="!smallScreen"> Vouchers</span>
          </a>
        </li>
        <li   *ngIf="roleName != 'PARTNER'">
          <a (click)="toggleSubMenu('reports')">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen">Reports</span>
            <span class="arrow" [class.active]="showSubMenu == 'reports'">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <defs>
                  <style>
                    .a {
                      fill: #f0f2f5;
                    }

                    .b {
                      fill: #03318c;
                    }
                  </style>
                </defs>
                <rect class="a" width="20" height="20" rx="4" />
                <path class="b"
                  d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                  transform="translate(0.736 -2.785)" />
              </svg>
            </span>
          </a>
          <ul class="submenu submenu1" *ngIf="showSubMenu === 'reports'">
            <li>
              <a [routerLink]="carReports" routerLinkActive="active">
                Car Rental Partners</a>
            </li>
            <li>
              <a [routerLink]="yachtReports" routerLinkActive="active">Yacht Rental Partners</a>
            </li>
          </ul>
        </li>
        <li  *ngIf="roleName === 'PARTNER'">
          <a [routerLink]="carReports" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <defs>
                <style>
                  .a {
                    fill: #f0f2f5;
                  }

                  .b {
                    fill: #03318c;
                  }
                </style>
              </defs>
              <rect class="a" width="20" height="20" rx="4" />
              <path class="b"
                d="M9.266,13.7l2.327-2.329a.438.438,0,0,1,.621,0,.443.443,0,0,1,0,.623L9.577,14.637a.439.439,0,0,1-.606.013L6.316,12a.44.44,0,0,1,.621-.623Z"
                transform="translate(0.736 -2.785)" />
            </svg>
            <span *ngIf="!smallScreen">Reports</span>
          </a>
        </li>
        <li *ngIf="roleName != 'PARTNER'">
          <a [routerLink]="configurations" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen"> Configurations </span>
          </a>
        </li>

        <li>
          <a [routerLink]="ourfleet" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen"> Our Fleet </span>
          </a>
        </li>

        <!-- DO NOT REMOVE -->
        <!-- <li *ngIf="roleName === 'SUPER_ADMIN'">
          <a [routerLink]="kpi" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen">
              KPI
            </span>
          </a>
        </li> -->

        <li *ngIf="roleName === 'SUPER_ADMIN'">
          <a [routerLink]="feed" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen"> Live Feed </span>
          </a>
        </li>
        <li *ngIf="roleName === 'SUPER_ADMIN'">
          <a [routerLink]="settings" routerLinkActive="active">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <path d="M-1291.915,1128.03v5.242h5.242A5.242,5.242,0,0,0-1291.915,1128.03Z"
                transform="translate(1299.673 -1128.03)" />
              <path class="a"
                d="M-1452.5,1128.03a6.5,6.5,0,0,0-6.5,6.5,6.5,6.5,0,0,0,6.5,6.5,6.5,6.5,0,0,0,6.5-6.5h-6.5Z"
                transform="translate(1459 -1128.03)" />
            </svg>
            <span *ngIf="!smallScreen"> Settings </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="copyright">
    <p class="mb-0">{{ "copyRights.text" | translate }}</p>
  </div>
</div>